<template>
  <v-container class="trip-detail padding-a-0">
    <div class="trip-detail-info">
      <v-form ref="trip-detail-form">
        <v-layout padded wrap>
          <v-flex xs12 md6>
            <v-layout padded>
              <v-flex xs6>
                <CRSelect
                  :id="`trip-${tripIndex}-type`"
                  v-model="tripData.tripTypeId"
                  :items="tripTypes"
                  item-text="label"
                  item-value="id"
                  label="Trip Type"
                  placeholder="e.g. Round Trip, One Way, etc."
                  browser-autocomplete="off"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Trip Type Is Required',
                            error: 'Trip Type Is Required',
                          }),
                        ]
                      : []
                  "
                />
              </v-flex>
              <v-flex xs3>
                <CRInput
                  :id="`trip-${tripIndex}-num-passengers`"
                  v-model="tripData.passengerCount"
                  label="Passengers"
                  type="number"
                  min="1"
                  placeholder="e.g. 10"
                  browser-autocomplete="off"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Number of Passengers Is Required',
                            error: 'Number of Passengers Is Required',
                          }),
                        ]
                      : []
                  "
                />
              </v-flex>
              <v-flex xs3>
                <CRInput
                  :id="`trip-${tripIndex}-num-drivers`"
                  v-model="tripData.requiredDrivers"
                  label="Drivers"
                  type="number"
                  min="1"
                  placeholder="e.g. 1"
                  browser-autocomplete="off"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Number of Drivers Is Required',
                            error: 'Number of Drivers Is Required',
                          }),
                        ]
                      : []
                  "
                />
              </v-flex>
            </v-layout>
            <v-checkbox
              v-model="tripData.vehicleNeededEntireTrip"
              :disabled="isModeView"
              label="Vehicle Required for Entire Trip"
              @change="$emit('vehicle-needed-entire-trip-changed', $event)"
            />
            <v-checkbox
              v-if="isCharterUpQuote && hasHotelRequirement"
              :input-value="isHotelRequired"
              class="margin-t-n6"
              :disabled="isModeView"
              label="Hotel Required for Driver"
              @change="(event) => handleDriverHotelCheck(event)"
            />
            <div v-if="isCharterUpQuote && isHotelRequired">
              <div
                v-if="showHotelInformation"
                class="margin-l-8 margin-t-n8 margin-b-6"
              >
                <v-layout flex align-center class="margin-b-n4">
                  <p>Hotel booked from</p>
                  <p class="font-weight-bold margin-l-1 margin-r-1">
                    {{
                      formatDisplayTime(hotelRequirement.checkIn) ||
                      'check-in date'
                    }}
                  </p>
                  <p>to</p>
                  <p class="font-weight-bold margin-l-1">
                    {{
                      formatDisplayTime(hotelRequirement.checkOut) ||
                      'check-out date'
                    }}
                  </p>
                  <p
                    v-if="isModeEdit"
                    class="edit-button"
                    @click="showDriverHotelSidebar"
                  >
                    Edit
                  </p>
                </v-layout>
                <span>
                  {{
                    `${hotelInformation.hotelName} – ${hotelInformation.confirmationNumber}`
                  }}
                </span>
                <br />
                <span>
                  {{ addressPretty({ address: hotelInformation.address }) }}
                </span>
                <br />
                <span>{{ hotelInformation.nameOnReservation }}</span>
              </div>
              <div v-else class="margin-l-8 margin-t-n8 margin-b-6">
                <v-layout flex align-center>
                  <p>Needed from</p>
                  <p class="font-weight-bold margin-l-1 margin-r-1">
                    {{ formatDisplayTime(firstStopDate) }}
                  </p>
                  <p>to</p>
                  <p class="font-weight-bold margin-l-1">
                    {{ formatDisplayTime(lastStopDate) }}
                  </p>
                  <p
                    v-if="isModeEdit"
                    class="edit-button"
                    @click="showDriverHotelSidebar"
                  >
                    Edit
                  </p>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 md6>
            <div
              v-for="(vehicle, vehicleIndex) in tripData.requiredVehicles"
              :key="`trip-${tripIndex}-vehicle-type-${vehicleIndex}`"
            >
              <v-form ref="trip-vehicle-detail-form">
                <v-layout padded>
                  <v-flex xs5>
                    <CRSelect
                      :id="`trip-${tripIndex}-vehicle-type-${vehicleIndex}`"
                      v-model="vehicle.vehicleType.id"
                      :items="vehicleTypes"
                      item-value="id"
                      item-text="label"
                      label="Vehicle Type"
                      placeholder="e.g. Charter Bus, Limousine, etc"
                      browser-autocomplete="off"
                      :disabled="isModeView"
                      :rules="
                        formSubmitted
                          ? [
                              isRequired(true, isNotEmpty, {
                                req: 'Vehicle Type Is Required',
                                error: 'Vehicle Type Is Required',
                              }),
                            ]
                          : []
                      "
                    />
                  </v-flex>
                  <v-flex xs5>
                    <CRInput
                      :id="`trip-${tripIndex}-num-vehicles-${vehicleIndex}`"
                      v-model="vehicle.quantity"
                      label="Vehicles"
                      type="number"
                      min="1"
                      placeholder="e.g. 1"
                      browser-autocomplete="off"
                      :disabled="isModeView"
                      :rules="
                        formSubmitted
                          ? [
                              isRequired(true, isNotEmpty, {
                                req: 'Number of Vehicles Is Required',
                                error: 'Number of Vehicles Is Required',
                              }),
                            ]
                          : []
                      "
                    />
                  </v-flex>
                  <v-flex xs1 justify-center layout column>
                    <v-btn
                      v-if="isModeEdit && tripData.requiredVehicles.length > 1"
                      icon
                      @click="removeVehicle(vehicleIndex)"
                    >
                      <CRIcon class="text-gray-mid-light hover:text-error">
                        trash
                      </CRIcon>
                    </v-btn>
                  </v-flex>

                  <div>&nbsp;</div>
                </v-layout>
              </v-form>
            </div>
            <p>
              <a v-if="isModeEdit" @click="addVehicle">
                + Add Another Vehicle Type
              </a>
            </p>
          </v-flex>
        </v-layout>
      </v-form>

      <v-layout
        v-if="itineraryWarnings.length"
        row
        justify-start
        class="margin-l-2"
      >
        <template v-for="(warning, warningIndex) in itineraryWarnings">
          <div
            v-if="warning.isDeactivatable"
            :key="`deactivatable-trip-itinerary-warning-${warningIndex}`"
            class="d-flex align-center margin-r-4"
          >
            <CRIcon
              view-box="0 0 24 20"
              :width="18"
              :height="18"
              :class="`text-${warning.class}`"
            >
              {{ warning.icon }}
            </CRIcon>
            <span
              class="whitespace-no-wrap margin-l-1 margin-r-2 margin-t-1 d-flex align-center"
              :class="`text-${
                warning.class === 'error-new' ? warning.class : 'black'
              }`"
            >
              {{ warning.label }}
            </span>
            <v-tooltip top>
              <template #activator="action">
                <div
                  class="margin-t-1 text-primary font-weight-bold font-12 cursor-pointer d-flex align-center"
                  v-on="action.on"
                >
                  <div @click="handleDisableWarningClick(warning.riskTypeId)">
                    Disable warning
                  </div>
                  <CRIcon
                    class="margin-t-0 margin-l-1 cursor-default"
                    view-box="0 0 24 20"
                    :width="16"
                    :height="16"
                  >
                    info
                  </CRIcon>
                </div>
              </template>
              <div class="max-w-200">
                {{ warning.tooltipCopy }}
              </div>
            </v-tooltip>
          </div>
        </template>
      </v-layout>
      <v-layout
        v-if="itineraryWarnings.length"
        row
        justify-start
        class="margin-l-2 margin-t-2 margin-b-4"
      >
        <template v-for="(warning, warningIndex) in itineraryWarnings">
          <div
            v-if="!warning.isDeactivatable"
            :key="`trip-itinerary-warning-${warningIndex}`"
            class="d-flex align-center"
            :class="{ 'cursor-pointer': isWarningClickable(warning) }"
            @click="handleWarningClick(warning)"
          >
            <CRIcon
              :key="`trip-itinerary-warning-icon-${warningIndex}`"
              view-box="0 0 24 20"
              :width="18"
              :height="18"
              :class="`text-${warning.class}`"
            >
              {{ warning.icon }}
            </CRIcon>
            <span
              class="whitespace-no-wrap margin-l-1 margin-r-2 margin-t-1"
              :class="`text-${
                warning.class === 'error-new' ? warning.class : 'black'
              }`"
            >
              {{ warning.label }}
            </span>
          </div>
        </template>
      </v-layout>

      <Draggable
        :list="tripData.stops"
        :disabled="isModeView"
        ghost-class="ghost"
        @start="() => updateCachedStops()"
        @end="() => handleReorderedStops()"
      >
        <div
          v-for="(stop, stopIndex) in tripData.stops"
          :key="`trip-${tripIndex}-stop-${stopIndex}`"
          style="margin-top: 10px"
        >
          <v-form ref="trip-stop-detail-form">
            <v-layout align-start fill-height>
              <v-flex xs11>
                <v-layout
                  row
                  wrap
                  align-end
                  padded
                  class="padding-a-2 border-1 border-radius-4 border-solid border-border-gray"
                >
                  <v-flex
                    v-if="
                      shouldPickupShow(tripData.stops, stopIndex) ||
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    xs12
                    lg4
                    order-xs1
                    order-lg1
                    class="padding-l-2"
                  >
                    <div class="d-flex align-center">
                      <template v-if="!op(stop, 'address/addressName')">
                        <CRAddressSelectorV2
                          v-if="isKnownAddressEditor"
                          :id="`trips-form-trip-${tripIndex}-stop-${stopIndex}-address-name`"
                          class="cr-input-stop-address"
                          :label="getStopLabel(stop, stopIndex)"
                          placeholder="Type to search"
                          :disabled="isModeView"
                          :prefill-items="tripDataStopsV2"
                          legacy-border
                          :style="
                            $cr.breakpoint.smAndDown
                              ? `padding-bottom: 18px`
                              : `padding-bottom: 29px`
                          "
                          prefill-numbering-key="stopIndex"
                          :rules="
                            formSubmitted
                              ? [
                                  isRequired(
                                    true,
                                    () => validateAddress(stop.address),
                                    {
                                      req: 'Stop Address Is Required',
                                      error: 'Stop Address Is Required',
                                    }
                                  ),
                                  (v) =>
                                    validateAddressState(stop.address) ||
                                    'Address Must Contain State',
                                  (v) =>
                                    validateAddressCountry(stop.address) ||
                                    'Address Must Contain Country',
                                ]
                              : []
                          "
                          @change:place="
                            placeSelected(
                              stop,
                              $event,
                              stopIndex === 0,
                              stopIndex
                            )
                          "
                        />
                        <CRAddressSelector
                          v-else
                          :id="`trips-form-trip-${tripIndex}-stop-${stopIndex}-address-name`"
                          class="cr-input-stop-address"
                          :label="getStopLabel(stop, stopIndex)"
                          placeholder="Type to search"
                          :use-formatted-detail="true"
                          :identifier="`trip_${tripIndex}_stop_${stopIndex}_address`"
                          :disabled="isModeView"
                          :prefill-items="tripDataStops"
                          legacy-border
                          :style="
                            $cr.breakpoint.smAndDown
                              ? `padding-bottom: 18px`
                              : `padding-bottom: 29px`
                          "
                          prefill-numbering-key="stopIndex"
                          :rules="
                            formSubmitted
                              ? [
                                  isRequired(
                                    true,
                                    () => validateAddress(stop.address),
                                    {
                                      req: 'Stop Address Is Required',
                                      error: 'Stop Address Is Required',
                                    }
                                  ),
                                  (v) =>
                                    validateAddressState(stop.address) ||
                                    'Address Must Contain State',
                                  (v) =>
                                    validateAddressCountry(stop.address) ||
                                    'Address Must Contain Country',
                                ]
                              : []
                          "
                          @place-selected="
                            (selectedData) =>
                              placeSelected(
                                stop,
                                selectedData,
                                stopIndex === 0 ? true : false,
                                stopIndex
                              )
                          "
                        />
                      </template>

                      <CRInput
                        v-show="stop.address && stop.address.addressName"
                        class="cr-input-stop-address"
                        :label="
                          op(stop, 'address/title')
                            ? `Stop #${stopIndex + 1} - ${op(
                                stop,
                                'address/title'
                              )}`
                            : `Stop #${stopIndex + 1}`
                        "
                        type="text"
                        clear-icon="replay"
                        :clearable="true"
                        :readonly="true"
                        :disabled="isModeView"
                        style="padding-bottom: 9px"
                        prepend-inner-icon="$vuetify.icons.bookIt"
                        :value="op(stop, 'address/addressName') || ''"
                        :rules="
                          formSubmitted
                            ? [
                                isRequired(
                                  true,
                                  () => validateAddress(stop.address),
                                  {
                                    req: 'Stop Address Is Required',
                                    error: 'Stop Address Is Required',
                                  }
                                ),
                                (v) =>
                                  validateAddressState(stop.address) ||
                                  'Address Must Contain State',
                                (v) =>
                                  validateAddressCountry(stop.address) ||
                                  'Address Must Contain Country',
                              ]
                            : []
                        "
                        @click:prepend-inner="() => editAddressTitle(stop)"
                        @click:clear="() => clearStopAddress(stop)"
                      />
                      <CRIcon
                        v-if="
                          !isModeView &&
                          isKnownAddressEditor &&
                          stop &&
                          stop.address &&
                          stop.address.addressName
                        "
                        :key="`known-address-icon-${stopIndex}-trip-${tripIndex}-${stop.address.knownAddressId}`"
                        color="primary"
                        class="max-w-24 margin-t-n1 margin-l-2"
                        view-box="0 0 24 24"
                        @click="editKnownAddress(stop.address, stopIndex)"
                      >
                        {{
                          stop.address.knownAddressId
                            ? 'place_filled'
                            : 'place_outline'
                        }}
                      </CRIcon>
                    </div>
                  </v-flex>
                  <!-- STARTING DROP OFF ROW -->
                  <v-flex
                    v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                    xs12
                    lg8
                    order-xs2
                    order-lg2
                  >
                    <v-layout align-end class="padding-b-2">
                      <v-flex xs4 text-xs-center style="padding-bottom: 30px">
                        <div class="stop-type-container margin-l-2">
                          <span
                            :class="{
                              'can-cancel': stopIndex > 0,
                              'smaller-text': $cr.breakpoint.mdAndDown,
                            }"
                          >
                            <span v-if="$cr.breakpoint.xsOnly">DO</span>
                            <span v-else>Drop-off</span>
                            <v-icon
                              v-if="isModeEdit"
                              class="stop-type-icon"
                              small
                              color="white"
                              @click="() => clearStopDateTime(stop, 'dropoff')"
                            >
                              cancel
                            </v-icon>
                          </span>
                        </div>
                      </v-flex>
                      <v-flex
                        v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                        xs4
                        class="padding-r-2"
                      >
                        <CRInput
                          v-model="stop.dropoffDate"
                          label="Drop-Off Date"
                          type="date"
                          :name="v4()"
                          :rules="[
                            (v) =>
                              callValidateStopDate(tripData.stops, stopIndex) ||
                              'Stop Dates Must Be Chronological',
                          ]"
                          :disabled="isModeView"
                          @input="
                            () => dateChange(stop, stopIndex, 'dropoff', false)
                          "
                        />
                      </v-flex>
                      <v-flex
                        v-if="shouldDropOffShow(tripData.stops, stopIndex)"
                        xs4
                      >
                        <CRInput
                          v-model="stop.dropoffTime"
                          label="Drop-Off Time"
                          type="time"
                          :class="
                            stopIndex === tripData.stops.length - 1
                              ? 'padding-r-2'
                              : ''
                          "
                          :rules="
                            isStopDatetimeValidationEnabled
                              ? [
                                  (v) =>
                                    validateStopTime(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Times Must Be Chronological',
                                ]
                              : []
                          "
                          :disabled="isModeView"
                          @change="calcHourlyDiff"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    lg4
                    order-xs5
                    order-sm4
                    order-lg3
                    align-self-start
                    class="padding-b-3"
                  >
                    <template v-if="hideStopNotePreview(stop)">
                      <v-btn
                        :id="`trip-detail-stop-${stopIndex}-button-add-spot-time`"
                        color="primary"
                        flat
                        small
                        class="margin-r-1"
                        @click="
                          showStopNoteDialog = true
                          editStopNote(stop)
                        "
                      >
                        Add Stop Note
                      </v-btn>
                      <template v-for="(stopRisk, index) in stop.risks">
                        <span
                          v-if="getRiskIcon(stopRisk.riskTypeId)"
                          :key="`stop-risk-${index}`"
                          class="margin-l-n1"
                          style="vertical-align: middle"
                        >
                          <CRIcon
                            view-box="0 0 24 18"
                            :width="16"
                            :height="16"
                            class="text-risk-warning"
                          >
                            {{ riskIconMap[stopRisk.riskTypeId] }}
                          </CRIcon>
                        </span>
                      </template>
                      <span
                        v-if="stop.flightInformation && !hasFlightRisk(stop)"
                        class="margin-x-1"
                        style="vertical-align: middle"
                      >
                        <CRIcon
                          view-box="0 0 24 18"
                          :width="18"
                          :height="18"
                          class="text-black"
                        >
                          airplane
                        </CRIcon>
                      </span>
                    </template>
                    <v-flex v-else xs12 class="padding-l-2">
                      <p>
                        Stop #{{ stopIndex + 1 }} Notes
                        <template v-for="(stopRisk, index) in stop.risks">
                          <span
                            v-if="getRiskIcon(stopRisk.riskTypeId)"
                            :key="`stop-risk-${index}`"
                            class="margin-x-1"
                            style="vertical-align: middle"
                          >
                            <CRIcon
                              view-box="0 0 24 18"
                              :width="16"
                              :height="16"
                              class="text-risk-warning"
                            >
                              {{ riskIconMap[stopRisk.riskTypeId] }}
                            </CRIcon>
                          </span>
                        </template>
                        <span
                          v-if="stop.flightInformation && !hasFlightRisk(stop)"
                          class="margin-x-1"
                          style="vertical-align: middle"
                        >
                          <CRIcon
                            view-box="0 0 24 18"
                            :width="18"
                            :height="18"
                            class="text-black"
                          >
                            airplane
                          </CRIcon>
                        </span>
                        <span
                          v-if="isModeEdit"
                          class="edit-button"
                          @click="
                            showStopNoteDialog = true
                            editStopNote(stop)
                          "
                        >
                          Edit
                        </span>
                      </p>
                      <template
                        v-if="
                          stop.orderIndex !== tripData.stops.length - 1 &&
                          isAirportAddress(stop.address.addressTypes) &&
                          stop.flightInformation !== null
                        "
                      >
                        <p class="margin-b-1">
                          Flight #:
                          {{ stop.flightInformation.flightNumber }}
                          <span class="margin-l-5">
                            Airline: {{ stop.flightInformation.airline }}
                          </span>
                        </p>
                      </template>
                      <p
                        v-if="stop.stopNotes && stop.stopNotes[0]"
                        v-html="
                          stop.stopNotes[0].html || stop.stopNotes[0].note
                        "
                      />
                      <p v-else>{{ stop.notes }}</p>
                    </v-flex>
                  </v-flex>
                  <!-- STARTING PICKUP ROW -->
                  <v-flex
                    v-if="shouldPickupShow(tripData.stops, stopIndex)"
                    :align-self-start="
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    xs12
                    lg8
                    :order-lg4="shouldDropOffShow(tripData.stops, stopIndex)"
                    :order-lg2="!shouldDropOffShow(tripData.stops, stopIndex)"
                    order-xs3
                  >
                    <v-layout align-end justify-end class="padding-b-2">
                      <v-flex
                        v-if="shouldPickupShow(tripData.stops, stopIndex)"
                        xs4
                        text-xs-center
                        style="padding-bottom: 30px"
                      >
                        <div class="stop-type-container margin-l-2">
                          <span
                            :class="{
                              'can-cancel': stopIndex > 0,
                              'smaller-text': $cr.breakpoint.mdAndDown,
                            }"
                          >
                            <span v-if="$cr.breakpoint.mdAndDown">PU</span>
                            <span v-else>Pickup</span>
                            <v-icon
                              v-if="isModeEdit && stopIndex > 0"
                              class="stop-type-icon"
                              small
                              color="white"
                              @click="() => clearStopDateTime(stop, 'pickup')"
                            >
                              cancel
                            </v-icon>
                          </span>
                        </div>
                      </v-flex>
                      <v-flex xs4 class="padding-y-0 padding-l-0 padding-r-2">
                        <CRInput
                          v-model="stop.pickupDate"
                          label="Pickup Date"
                          type="date"
                          :disabled="isModeView"
                          :name="v4()"
                          :rules="
                            formSubmitted &&
                            shouldValidateStopDateTime(
                              tripData.stops,
                              stopIndex
                            )
                              ? [
                                  isRequired(true, isNotEmpty, {
                                    req: 'Pickup Date Is Required',
                                    error: 'Pickup Date Is Required',
                                  }),
                                  (v) =>
                                    callValidateStopDate(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Dates Must Be Chronological',
                                ]
                              : [
                                  (v) =>
                                    callValidateStopDate(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Dates Must Be Chronological',
                                ]
                          "
                          @input="() => dateChange(stop, stopIndex, 'pickup')"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <CRInput
                          v-model="stop.pickupTime"
                          label="Pickup Time"
                          class="padding-r-0"
                          type="time"
                          :disabled="isModeView"
                          :rules="
                            formSubmitted &&
                            shouldValidateStopDateTime(
                              tripData.stops,
                              stopIndex
                            )
                              ? [
                                  isRequired(true, isNotEmpty, {
                                    req: 'Pickup Time Is Required',
                                    error: 'Pickup Time Is Required',
                                  }),
                                  (v) =>
                                    callValidateStopTime(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Times Must Be Chronological',
                                ]
                              : [
                                  (v) =>
                                    callValidateStopTime(
                                      tripData.stops,
                                      stopIndex
                                    ) || 'Stop Times Must Be Chronological',
                                ]
                          "
                          @change="onPickupChange(stop, stopIndex)"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- SPACER -->
                  <v-flex
                    v-if="
                      $cr.breakpoint.lgAndUp &&
                      shouldPickupShow(tripData.stops, stopIndex) &&
                      shouldDropOffShow(tripData.stops, stopIndex)
                    "
                    lg4
                    order-lg5
                  />
                  <v-flex
                    v-if="shouldPickupShow(tripData.stops, stopIndex)"
                    xs12
                    sm8
                    lg8
                    order-xs4
                    order-sm5
                    order-lg6
                    align-self-start
                  >
                    <v-layout justify-end class="padding-b-3">
                      <v-flex
                        v-if="
                          stop.spotTime &&
                          stop.spotTime.spotTime &&
                          stop.spotTime.active
                        "
                        xs12
                      >
                        <v-layout row class="margin-l-n1">
                          <v-flex xs0 lg4 />
                          <v-flex xs6 lg4 style="padding-right: 2px">
                            <CRSelect
                              v-if="stop.spotTime"
                              :id="`quote-form-trip-${tripIndex}-spot-drop-down`"
                              :value="getSpotTime(stop)"
                              :items="timeAtStopOptions"
                              item-value="value"
                              item-text="label"
                              label="Time at Stop"
                              placeholder="minutes"
                              browser-autocomplete="off"
                              :disabled="isModeView"
                              @input="setSpotTime(stop, $event)"
                            />
                          </v-flex>
                          <v-flex
                            v-if="stop.spotTime"
                            xs6
                            lg3
                            style="padding-left: 10px"
                          >
                            <div style="margin-bottom: 15px">Spot Time</div>

                            <span v-if="stop.spotTime.spotTime">
                              {{
                                formatTime(
                                  stop.spotTime.spotTime,
                                  stop.address.timeZone
                                )
                              }}
                            </span>
                          </v-flex>
                          <v-flex
                            v-if="isModeEdit"
                            xs1
                            justify-end
                            style="margin-top: 34px"
                          >
                            <v-icon
                              color="primary"
                              @click="clearSpotTime(stop)"
                            >
                              close
                            </v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex v-else xs6 class="text-primary">
                        <v-btn
                          v-if="isModeEdit"
                          :id="`trip-detail-stop-${stopIndex}-button-add-spot-time`"
                          color="primary"
                          flat
                          small
                          :disabled="!stop.pickupTime"
                          @click="addSpotTime(stop)"
                        >
                          Add Spot Time
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- STARTING EVENTS ROW -->
                  <v-flex v-if="events.length && stopIndex === 0" xs12>
                    <v-layout
                      v-for="(event, eventIndex) in events"
                      :key="`event-row-${eventIndex}`"
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        layout
                        class="align-center"
                        style="margin-left: 10px"
                      >
                        <CRIcon
                          class="margin-r-2"
                          :color="getEventSeverity(event)"
                        >
                          alert
                        </CRIcon>
                        <span
                          class="d-flex h-24 text-error font-16 font-bold"
                          style="flex: 0 0 auto"
                        >
                          Event Alert:
                        </span>
                        <b style="padding: 0px 5px">
                          {{ (event.title || '').toUpperCase() }}
                        </b>
                        {{
                          `${event.startDate} through ${event.endDate} ${
                            event.comments || ''
                          }`
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- BEGIN ICONS -->
              <v-flex
                xs1
                class="d-flex justify-center align-center flex-column"
              >
                <div class="h-111">
                  <v-btn
                    v-if="isModeEdit && tripData.stops.length > 1"
                    icon
                    class="margin-t-0"
                  >
                    <v-icon color="blue">unfold_more</v-icon>
                  </v-btn>
                  <br v-if="isModeEdit && tripData.stops.length > 1" />
                  <v-btn
                    v-if="isModeEdit && stopIndex > 0"
                    :id="`quote-form-trip-${tripIndex}-stop-${stopIndex}-calculate-departure-time`"
                    :disabled="isArrivalEstimatorDisabled(stopIndex)"
                    icon
                    class="margin-t-0"
                    @click="getTripEstimationForStop(stopIndex)"
                  >
                    <v-icon color="blue">schedule</v-icon>
                  </v-btn>
                  <br v-if="isModeEdit && stopIndex > 0" />
                  <v-btn
                    v-if="isModeEdit && tripData.stops.length > 1"
                    icon
                    class="margin-t-0"
                    @click="removeStop(stopIndex)"
                  >
                    <CRIcon class="text-gray-mid-light hover:text-error">
                      trash
                    </CRIcon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </Draggable>

      <v-dialog
        v-model="showStopAddressTitleDialog"
        max-width="400"
        height="400"
      >
        <v-card class="cr-modal">
          <v-card-title>
            <h2>Address Title</h2>
          </v-card-title>
          <v-card-text>
            <CRInput
              v-model="activeAddressTitle"
              type="text"
              :clearable="true"
              :autofocus="false"
              clear-icon="replay"
              :disabled="isModeView"
              @click:clear="activeAddressTitle = undefined"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="btn-secondaryaction"
              @click="showStopAddressTitleDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="margin-r-0 btn-primaryaction font-bold"
              :disabled="isModeView"
              style="margin-right: 0"
              @click="setStopAddressTitle"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-layout row justify-end padded class="pt-1 pb-3">
        <v-layout v-if="!isNewTripAmenitiesEnabled" xs6 row wrap>
          <v-flex shrink class="mr-3">
            <v-checkbox
              v-model="tripData.spab"
              :disabled="isModeView"
              label="SPAB"
            />
          </v-flex>
          <v-flex shrink class="mr-3">
            <v-checkbox
              v-model="tripData.mountain"
              :disabled="isModeView"
              label="Mountain Trip"
              style="white-space: nowrap"
            />
          </v-flex>
          <v-flex shrink>
            <v-checkbox
              v-model="tripData.ada"
              :disabled="isModeView"
              label="ADA"
            />
          </v-flex>
        </v-layout>
        <v-spacer />
        <v-flex shrink>
          <v-btn v-if="isModeEdit" class="btn-primaryaction" @click="addStop">
            Add Stop
          </v-btn>
        </v-flex>
        <v-flex xs1 />
      </v-layout>

      <div class="d-flex w-full flex-row">
        <TripsFormCalculations
          :trip-data="tripData"
          :toggled="false"
          :instance="'alpha'"
          style="margin-left: 6px"
        />
      </div>

      <div
        v-if="suggestedPricing"
        class="suggested-pricing-container justify-center w-300 h-40 margin-t-0 font-weight-800 align-center background-gray border-radius-top-left-20 border-radius-top-right-20 border-radius-bottom-right-20 border-radius-bottom-left-2"
      >
        <label class="padding-l-0 text-gray-light">Suggested Pricing:</label>
        <label class="pricing-type text-white">
          {{ suggestedPricing.pricingType || 'Mileage' }}
        </label>
        <label class="price text-shofur">
          {{ filterCurrency(suggestedPricing.priceHigh) }}
        </label>
      </div>
    </div>
    <div class="d-flex">
      <v-checkbox
        v-if="!isModeView"
        :input-value="tripData.isEnterprise"
        hide-details
        color="primary"
        label="Enterprise"
        style="width: 106px !important; max-width: 106px !important"
        @change="tripData.isEnterprise = !!$event"
      />
      <slot name="inline-buttons" />
    </div>
    <slot name="buttons" />
  </v-container>
</template>

<script>
import op from 'simple-object-path'
import { authComputed } from '@/state/helpers'

import { deepClone } from '@/utils/deepClone'
import events from '@/services/events'
import { getDistanceAndDurationBetweenLocations } from '@/services/distance'
import { currencyFilter } from '@/utils/currency'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  validateAddress,
  validateAddressState,
  validateAddressCountry,
  validateTripFormGroups,
  validateStopDate,
  validateStopTime,
} from '@/utils/validators'

import Draggable from 'vuedraggable'
import TripsFormCalculations from './TripsFormCalculations.vue'
import { DateTime, Duration } from 'luxon'
import { mapActions, mapGetters } from 'vuex'
import { v4 } from 'uuid'
import { EventBus } from '@/utils/event-bus'
import { buildAddressTypes, isAirportAddress } from '@/utils/address'
import { RiskTypeId, SplitFeatureFlag } from '@/utils/enum'
import { addressPretty } from '@/utils/string'
import { includesRisk, STOP_LEVEL_RISKS, TRIP_LEVEL_RISKS } from '@/utils/risk'
import * as logger from '@/utils/logger'

const PARENT_RESERVATION_TYPE = 0

export default {
  components: {
    Draggable,
    TripsFormCalculations,
  },
  model: {
    prop: 'tripData',
  },
  props: {
    reservationId: { type: Number, default: 0 },
    toggled: { type: Boolean, default: false },
    validationKey: { type: String, default: undefined },
    distanceUpdateKey: { type: String, default: undefined },
    tripIndex: { type: Number, default: null },
    tripTypes: { type: Array, default: () => [] },
    vehicleTypes: { type: Array, default: () => [] },
    suggestedPricing: { type: Object, default: () => undefined },
    tripData: {
      type: Object,
      default: () => ({ rates: [], charges: [], stops: [] }),
    },
    hotelInformation: { type: Object, default: () => undefined },
    hotelRequirement: { type: Object, default: () => undefined },
    eventByLocation: { type: Function, default: () => true },
    mode: { type: String, default: undefined },
    isCharterUpQuote: {
      type: Boolean,
      default: false,
    },
    reservationRisks: { type: Object, default: () => undefined },
    incompleteItinerary: { type: Boolean, default: false },
  },
  data() {
    return {
      op,
      isNotEmpty,
      isRequired,
      validateEmail,
      validateAddress,
      validateAddressState,
      validateAddressCountry,
      validateStopDate,
      validateStopTime,
      filterCurrency: currencyFilter,
      isAirportAddress,
      addressPretty,
      formSubmitted: false,
      mockitems: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      showStopAddressTitleDialog: false,
      showStopNoteDialog: false,
      activeAddressTitle: undefined,
      activeStop: undefined,
      activeTrip: undefined,
      activeTripNotes: undefined,
      activeOfficeNotes: undefined,
      eventSeverityClass: undefined,
      events: [],
      cachedStops: [],
      stopTemplate: {
        active: true,
        address: null,
        createdOn: null,
        updatedOn: null,
        isPickupEstimated: false,
        isDropoffEstimated: false,
        isArrived: false,
        orderIndex: 0,
        startDatetime: null,
        pickupDatetime: null,
        dropoffDatetime: null,
        stopType: 'Pickup',
        risks: null,
        flightInformation: null,
      },
      vehicleTemplate: { vehicleType: { id: null }, quantity: 1 },
      timeAtStopOptions: [
        {
          label: '5 Minutes',
          value: 5,
        },
        {
          label: '10 Minutes',
          value: 10,
        },
        {
          label: '15 Minutes',
          value: 15,
        },
        {
          label: '20 Minutes',
          value: 20,
        },
        {
          label: '25 Minutes',
          value: 25,
        },
        {
          label: '30 Minutes',
          value: 30,
        },
        {
          label: '45 Minutes',
          value: 45,
        },
        {
          label: '60 Minutes',
          value: 60,
        },
      ],
      riskIconMap: {},
      isNewTripAmenitiesEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isStopDatetimeValidationEnabled:
        'featureToggles/isStopDatetimeValidationEnabled',
    }),
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeView() {
      return this.mode === 'view'
    },
    showHotelInformation() {
      return (
        this.hotelInformationComplete &&
        this.isDriverHotelRequired &&
        !this.itineraryDriverHotelRisk
      )
    },
    hotelInformationComplete() {
      if (!this.hotelInformation) {
        return false
      }
      const {
        address,
        hotelName,
        confirmationNumber,
        nameOnReservation,
      } = this.hotelInformation
      return !!(
        address &&
        hotelName &&
        confirmationNumber &&
        nameOnReservation &&
        this.hotelRequirement?.checkIn &&
        this.hotelRequirement?.checkOut
      )
    },
    isDriverHotelRequired() {
      return this.hotelRequirement?.isRequired
    },
    tripDataStopsV2() {
      const stops = this.tripData?.stops
      const tripDataStops = []
      for (let i = 0; i < stops?.length; i++) {
        tripDataStops.push({ address: stops[i]?.address, stopIndex: i + 1 })
      }

      return tripDataStops
        ?.filter((s) => !!s?.address)
        ?.filter((s) => !!s?.address?.addressName)
        .map((s) => {
          return { ...s.address, stopIndex: s.stopIndex }
        })
    },
    tripDataStops() {
      let tripDataStops = []

      for (let i = 0; i < this.tripData.stops?.length; i++) {
        if (this.tripData.stops[i].address?.addressName) {
          tripDataStops.push({
            place: this.tripData.stops[i].address,
            description: this.tripData.stops[i].address?.addressName,
            defaultAddressTitle: this.tripData.stops[i].address?.title,
            stopIndex: i + 1,
          })
        }
      }

      return tripDataStops
    },
    firstStopDate() {
      if (!this.tripData?.stops) {
        return null
      }
      for (const stop of deepClone(this.tripData.stops)) {
        if (stop?.pickupDate) {
          return stop.pickupDate
        }
      }
      return null
    },
    lastStopDate() {
      if (!this.tripData?.stops) {
        return null
      }
      for (const stop of deepClone(this.tripData.stops).reverse()) {
        if (stop?.pickupDate) {
          return stop.pickupDate
        } else if (stop?.dropoffDate) {
          return stop.dropoffDate
        }
      }
      return null
    },
    stopRisks() {
      const stopRisks = this.tripData?.stops?.flatMap((stop) => stop.risks)
      return stopRisks || []
    },
    itineraryAddressRisk() {
      const addressRisks = this.tripData?.stops?.flatMap(
        (stop) => stop.address?.risks
      )
      return includesRisk(addressRisks, RiskTypeId.AddressRisk)
    },
    itineraryDriverHotelRisk() {
      return includesRisk(this.reservationRisks, RiskTypeId.DriverHotelRisk)
    },
    itineraryFlightRisk() {
      return includesRisk(this.stopRisks, RiskTypeId.FlightRisk)
    },
    itineraryMissingStopRisk() {
      return includesRisk(this.stopRisks, RiskTypeId.MissingStopRisk)
    },
    itineraryDOTComplianceRisk() {
      return includesRisk(this.reservationRisks, RiskTypeId.DOTComplianceRisk)
    },
    itineraryIncompleteStopRisk() {
      return includesRisk(this.stopRisks, RiskTypeId.IncompleteStopRisk)
    },
    itineraryMissingPickupTimeRisk() {
      return includesRisk(this.stopRisks, RiskTypeId.MissingPickupTimeRisk)
    },
    itineraryWarnings() {
      const warnings = []
      if (this.itineraryDriverHotelRisk) {
        warnings.push({
          riskTypeId: RiskTypeId.DriverHotelRisk,
          icon: 'alert_filled',
          label: 'Missing driver hotel',
          class: 'risk-warning',
          isDeactivatable: false,
        })
      }
      if (this.itineraryAddressRisk) {
        warnings.push({
          riskTypeId: RiskTypeId.AddressRisk,
          icon: 'alert_filled',
          label: 'Incomplete address',
          class: 'risk-warning',
          isDeactivatable: false,
        })
      }
      if (this.itineraryFlightRisk) {
        warnings.push({
          riskTypeId: RiskTypeId.FlightRisk,
          icon: 'alert_filled',
          label: 'Missing flight info',
          class: 'risk-warning',
          isDeactivatable: false,
        })
      }
      if (this.itineraryMissingPickupTimeRisk) {
        warnings.push({
          riskTypeId: RiskTypeId.MissingPickupTimeRisk,
          icon: 'alert_filled',
          label: 'Missing pickup time',
          class: 'risk-warning',
          isDeactivatable: false,
        })
      }
      if (this.itineraryMissingStopRisk || this.itineraryIncompleteStopRisk) {
        const riskTypeId = this.itineraryMissingStopRisk
          ? RiskTypeId.MissingStopRisk
          : RiskTypeId.IncompleteStopRisk
        warnings.push({
          riskTypeId,
          icon: 'alert_filled',
          label: 'May have missing stops',
          class: 'risk-warning',
          isDeactivatable: true,
          tooltipCopy:
            'Add the missing stops, or disable the warning if the itinerary is complete.',
        })
      }
      if (this.itineraryDOTComplianceRisk) {
        warnings.push({
          riskTypeId: RiskTypeId.DOTComplianceRisk,
          icon: 'alert_filled',
          label: 'Confirm DOT compliance',
          class: 'risk-warning',
          isDeactivatable: true,
          tooltipCopy:
            'If the daily itinerary is within DOT limits, disable the warning.',
        })
      }
      return warnings
    },
    hasHotelRequirement() {
      const hotelRequirement = this.$store.getters[
        'tripHotelRequirement/hotelRequirement'
      ]
      return !!hotelRequirement && Object.keys(hotelRequirement).length > 0
    },
    isHotelRequired() {
      return this.$store.getters['tripHotelRequirement/isRequired']
    },
  },
  watch: {
    validationKey() {
      this.validateForms()
    },
    distanceUpdateKey() {
      this.updateDistance()
    },
    lastStopDate() {
      if (this.lastStopDate) {
        this.checkEvents()
      }
    },
    'tripData.tripId'(value) {
      this.$store.dispatch('tripHotelRequirement/setTripId', value)
    },
    hotelRequirement() {
      this.initializeDriverHotelRequirement()
    },
  },
  async mounted() {
    this.isNewTripAmenitiesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
    EventBus.$on('add-new-stop-note', (stop, note, html) => {
      if (stop.stopNotes == null) {
        stop.stopNotes = []
      }
      stop.stopNotes.push({
        note: note,
        html: html,
      })
    })

    EventBus.$on(
      `quote-form-trip-${this.tripIndex}-edit-stop-location`,
      (address, stopIndex) => {
        this.tripData.stops[stopIndex].address = address
      }
    )

    await this.mapRiskTypesToIcons()
  },
  beforeDestroy() {
    EventBus.$off(`quote-form-trip-${this.tripIndex}-edit-stop-location`)
  },
  methods: {
    ...mapActions({
      getQuoteTripEstimations: 'quotes/getQuoteTripEstimations',
      openSidebarDialog: 'app/openSidebarDialog',
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    v4,
    callValidateStopDate(stops, stopIndex) {
      if (!this.isStopDatetimeValidationEnabled) {
        return true
      }

      return validateStopDate(stops, stopIndex)
    },
    callValidateStopTime(stops, stopIndex) {
      if (!this.isStopDatetimeValidationEnabled) {
        return true
      }

      return validateStopTime(stops, stopIndex)
    },
    shouldDropOffShow(stops, stopIndex, isAddress) {
      if (stopIndex === 0) {
        return false
      }
      if (isAddress && stopIndex === stops.length - 1) {
        return false
      }
      return true
    },
    shouldPickupShow(stops, stopIndex) {
      if (stops.length > 1 && stopIndex === stops.length - 1) {
        return false
      }
      return true
    },
    async mapRiskTypesToIcons() {
      const {
        data: { resultList },
      } = await this.$store.dispatch('types/getRiskTypes')
      for (const riskType of resultList) {
        const { riskTypeId } = riskType
        switch (riskTypeId) {
          case 4:
            this.riskIconMap[riskTypeId] = 'airplane'
            break
        }
      }
    },
    getEventSeverity(event) {
      if (!event) {
        return
      }
      const hasHighOrExtremeEvent = ['high', 'extreme'].includes(
        event.severityTypeKey
      )
      const hasMediumEvent = ['medium'].includes(event.severityTypeKey)
      const hasLowEvent = ['low'].includes(event.severityTypeKey)

      if (hasHighOrExtremeEvent) {
        return 'error'
      } else if (hasMediumEvent) {
        return 'warn'
      } else if (hasLowEvent) {
        return 'success'
      }
    },
    formatTime(timeObject, zone) {
      return DateTime.fromISO(timeObject, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    initializeDriverHotelRequirement() {
      const hotelRequirement = deepClone(this.hotelRequirement) || {
        isRequired: false,
        tripId: this.tripData.tripId,
      }
      this.$store.dispatch(
        'tripHotelRequirement/setHotelRequirement',
        hotelRequirement
      )
    },
    getDistanceAndDurationBetweenLocations,
    async updateDistance() {
      const stopAddressArray = this.tripData.stops.map((stop) => {
        const { address } = stop
        const { lat = null, lng = null } = address || {}
        if (lat === null && lng === null) {
          return null
        }
        return { lat, lng }
      })
      const hasEmpty = stopAddressArray.some((latLng) => latLng === null)
      if (!hasEmpty) {
        const tripEstimationsRequest = await this.buildTripEstimationsPayload()
        const tripEstimationsData = tripEstimationsRequest.data
        const currentTripEstimation = tripEstimationsData[this.tripIndex]

        this.tripData.distance = currentTripEstimation.distance
        this.tripData.drivingTime = currentTripEstimation.duration
        this.tripData.endDate = currentTripEstimation.estimatedEndDate
        this.setCalendarDays()
      }
    },
    addStop() {
      const stops = this.tripData?.stops || []
      const newStop = deepClone(this.stopTemplate)
      if (stops.length > 0) {
        const lastStop = stops[stops.length - 1]
        if (lastStop && typeof lastStop.orderIndex !== 'undefined') {
          newStop.orderIndex = parseInt(lastStop.orderIndex, 10) + 1
        }
      }
      const prevLastStopIndex = stops.length - 1
      this.tripData.stops.push(newStop)
      const { flightInformation, risks } = this.getStopFlightRisk(
        this.tripData.stops[prevLastStopIndex]
      )
      this.tripData.stops[prevLastStopIndex].risks = risks
      this.tripData.stops[
        prevLastStopIndex
      ].flightInformation = flightInformation
      this.updateDistance()
    },
    removeStop(stopIndex) {
      this.tripData.stops.splice(stopIndex, 1)
      this.tripData.stops.forEach((stop, index) => (stop.orderIndex = index))

      const lastIndex = this.tripData.stops.length - 1
      const { flightInformation, risks } = this.getStopFlightRisk(
        this.tripData.stops[lastIndex]
      )
      this.tripData.stops[lastIndex].risks = risks
      this.tripData.stops[lastIndex].flightInformation = flightInformation

      if (this.tripData.stops[lastIndex].pickupDatetime) {
        this.tripData.stops[lastIndex].pickupDate = null
        this.tripData.stops[lastIndex].pickupDatetime = null
        this.tripData.stops[lastIndex].pickupTime = null
      }
      if (this.tripData.stops[0].dropoffDatetime) {
        this.tripData.stops[0].dropoffDate = null
        this.tripData.stops[0].dropoffDatetime = null
        this.tripData.stops[0].dropoffTime = null
      }
      this.updateDistance()
    },
    onPickupChange(stop, stopIndex) {
      this.calcHourlyDiff()

      if (stop.spotTime) {
        this.setSpotTime(stop)
      }

      this.$set(this.tripData.stops, stopIndex, stop)
    },
    calcHourlyDiff() {
      const { stops = [] } = this.tripData
      const dates = []
      stops.forEach((stop, stopIndex) => {
        const { pickupDate, dropoffDate, pickupTime, dropoffTime } = stop

        if (pickupDate && stopIndex !== stops.length - 1) {
          if (pickupDate && pickupTime) {
            dates.push(DateTime.fromISO(pickupDate + 'T' + pickupTime))
          } else {
            dates.push(DateTime.fromISO(pickupDate))
          }
        }
        if (dropoffDate && stopIndex !== 0) {
          if (dropoffDate && dropoffTime) {
            dates.push(DateTime.fromISO(dropoffDate + 'T' + dropoffTime))
          } else {
            dates.push(DateTime.fromISO(dropoffDate))
          }
        }

        if (stop?.spotTime?.timeAtStop) {
          this.setSpotTime(stop, stop.spotTime.timeAtStop)
        }
      })
      dates.sort((a, b) => (a < b ? -1 : 0))
      let hourlyDiff = 1
      if (dates.length === 1) {
        hourlyDiff = 1
      } else if (dates.length === 0) {
        hourlyDiff = 0
      } else {
        const first = dates.shift()
        const last = dates.pop()
        hourlyDiff = Math.trunc(last.diff(first, 'hours').as('hours'))
      }

      this.tripData.hourlyDiff = hourlyDiff
    },
    setCalendarDays() {
      const { stops = [] } = this.tripData
      const dates = []
      stops.forEach((stop, stopIndex) => {
        const { pickupDate, dropoffDate } = stop
        if (pickupDate && stopIndex !== stops.length - 1) {
          dates.push(DateTime.fromISO(pickupDate))
        }
        if (dropoffDate && stopIndex !== 0) {
          dates.push(DateTime.fromISO(dropoffDate))
        }
      })
      dates.sort((a, b) => (a < b ? -1 : 0))
      let calendarDays = 1
      if (dates.length === 1) {
        calendarDays = 1
      } else if (dates.length === 0) {
        calendarDays = 0
      } else {
        const first = dates.shift()
        const last = dates.pop()
        const diff = Math.trunc(last.diff(first, 'days').as('days'))
        calendarDays = diff + 1
      }

      this.tripData.calendarDays = calendarDays
      this.calcHourlyDiff()
    },
    clearStopDateTime(stop, stopType) {
      stop[`${stopType}Date`] = null
      stop[`${stopType}Time`] = null
      stop[`${stopType}_datetime`] = null
      stop[`${stopType}DateTime`] = null
      stop[`${stopType}Datetime`] = null
      this.setCalendarDays()
    },
    placeSelected(stop, selectedData, checkEvents = false, stopIndex) {
      selectedData.place.addressTypes =
        selectedData.place?.addressTypes ||
        buildAddressTypes(selectedData?.place?.types)
      delete selectedData.place.types
      stop.address = selectedData.place
      stop.address.title = selectedData.defaultAddressTitle
      this.updateDistance()
      if (checkEvents) {
        this.checkEvents(stop)
      }
      const { flightInformation, risks } = this.getStopFlightRisk(
        this.tripData.stops[stopIndex]
      )
      this.tripData.stops[stopIndex].flightInformation = flightInformation
      this.tripData.stops[stopIndex].risks = risks
    },
    updateCachedStops() {
      this.cachedStops = [...this.tripData.stops]
    },
    async handleReorderedStops() {
      this.tripData.stops.forEach((newStop, i) => {
        let oldStop = this.cachedStops[i]

        if (JSON.stringify(newStop) !== JSON.stringify(oldStop)) {
          newStop.orderIndex = i
          if (i == this.tripData.stops.length - 1) {
            newStop.pickupDate = null
            newStop.pickupTime = null
            newStop.pickupDatetime = null
            newStop.flightInformation = null
            newStop.risks = null
          } else {
            if (i === 0) {
              newStop.dropoffDate = null
              newStop.dropoffTime = null
              newStop.dropoffDatetime = null
            }
            const { flightInformation, risks } = this.getStopFlightRisk(newStop)
            newStop.flightInformation = flightInformation
            newStop.risks = risks
          }
        }
      })

      if (this.validationKey) {
        this.validateForms()
      }
      this.setCalendarDays()
      await this.checkEvents()
      await this.updateDistance()
    },
    async checkEvents(stop) {
      this.events = []
      if (!stop) {
        stop = this.tripData?.stops?.[0] || {}
      }
      const { pickupDate, address } = stop
      if (!address || !pickupDate || !this.lastStopDate) {
        return
      }
      if (address && pickupDate && this.lastStopDate) {
        const { lat, lng } = address
        const params = {
          pickupDate,
          dropOffDate: this.lastStopDate,
          lat,
          lng,
          radius: 100,
        }
        const eventsData = await events.eventByLocation(params)
        const eventList = eventsData.data || []
        this.events = eventList.map((event) => {
          event.startDate = DateTime.fromISO(event.startDate)
            .toUTC()
            .toISODate()
          event.endDate = DateTime.fromISO(event.endDate).toUTC().toISODate()
          return event
        })
        this.events.sort(this.eventComparator)
      }
    },
    eventComparator(a, b) {
      let aNum = this.getEventComparatorNumeric(a)
      let bNum = this.getEventComparatorNumeric(b)
      return aNum >= bNum
    },
    getEventComparatorNumeric(obj) {
      switch (obj.severityTypeKey) {
        case 'extreme':
          return 4
        case 'high':
          return 3
        case 'medium':
          return 2
        case 'low':
          return 1
        default:
          return 0
      }
    },
    clearStopAddress(stop) {
      stop.address = {}
      stop.flightInformation = null
      stop.risks = null
      this.updateDistance()
      this.activeAddressTitle = undefined
      this.activeStop = undefined
    },
    editAddressTitle(stop) {
      this.activeStop = stop
      if (stop.address && stop.address.title) {
        this.activeAddressTitle = stop.address.title
      } else {
        this.activeAddressTitle = undefined
      }
      this.showStopAddressTitleDialog = true
    },
    setStopAddressTitle() {
      if (this.activeStop.address) {
        this.activeStop.address.title = this.activeAddressTitle
        this.activeAddressTitle = undefined
      }
      this.showStopAddressTitleDialog = false
    },
    addSpotTime(stop) {
      stop.spotTime = {
        spotTime: null,
        timeAtStop: 15,
        active: true,
      }
      this.setSpotTime(stop)
    },
    getSpotTime(stop) {
      const spotTime = stop.spotTime.spotTime
      const pickupDate = stop.pickupDate
      const pickupTime = stop.pickupTime
      if (!spotTime || !pickupDate || !pickupTime) return null

      let spotTimeObj = DateTime.fromISO(spotTime)
      let pickupTimeObj = DateTime.fromISO(stop.pickupDatetime)
      let diff = spotTimeObj.diff(pickupTimeObj, 'minutes')

      let timeAtStop = Math.round(Math.abs(diff.minutes) / 5) * 5
      if (!stop.spotTime.timeAtStop) {
        stop.spotTime.timeAtStop = timeAtStop
      }
      return timeAtStop
    },
    setSpotTime(stop, event) {
      stop.spotTime.timeAtStop = event ? event : 15

      const MILLIS_TO_MINUTES = 1000 * 60
      const durationDateTime = Duration.fromMillis(
        stop.spotTime?.timeAtStop * MILLIS_TO_MINUTES
      )

      let pickupTimeObj = DateTime.fromFormat(
        `${stop.pickupDate} ${stop.pickupTime} ${stop.address.timeZone}`,
        'yyyy-MM-dd HH:mm z',
        { setZone: true }
      )

      stop.pickupDatetime = pickupTimeObj.toISO()

      stop.spotTime.spotTime = DateTime.fromISO(stop.pickupDatetime)
        .minus(durationDateTime)
        .toISO()
      this.$forceUpdate()
    },
    clearSpotTime(stop) {
      stop.spotTime = null
      this.$forceUpdate()
    },
    editStopNote(stop) {
      const component = () => import('./StopInfoSidebar.vue')
      this.activeStop = stop
      const isLastStop = stop.orderIndex === this.tripData?.stops.length - 1
      const isAirportStop =
        this.isAirportAddress(stop.address?.addressTypes) && !isLastStop
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          reservationId: this.reservationId,
          activeStop: this.activeStop,
          reservationType: 0,
          title: 'Stop Notes',
          isAirportStop,
          isLastStop,
        },
        component,
      })
    },
    addVehicle() {
      this.tripData.requiredVehicles.push(
        Object.assign({}, deepClone(this.vehicleTemplate))
      )
    },
    removeVehicle(vehicleIndex) {
      if (this.tripData.requiredVehicles.length > 1) {
        this.tripData.requiredVehicles.splice(vehicleIndex, 1)
      }
    },
    validateForms() {
      const formNames = [
        'trip-detail-form',
        'trip-vehicle-detail-form',
        'trip-stop-detail-form',
      ]
      validateTripFormGroups.call(this, formNames, 'detail')
    },
    shouldValidateStopDateTime(stops, stopIndex) {
      if (stopIndex === 0) {
        return true
      }
      if (stops.length > 1 && stopIndex === stops.length - 1) {
        return true
      }
      return false
    },
    dateChange(stop, stopIndex, stopType, setDueDateKey = true) {
      const currentValue = stop[`${stopType}Time`]
      const currentValueDate = stop[`${stopType}Date`]

      if (!currentValue && currentValueDate) {
        stop[`${stopType}Time`] = '12:00'
      }
      if (stopIndex !== null && setDueDateKey) {
        this.setDueDateKey(stopIndex)
      }
      if (stop !== null && !!stop.spotTime) {
        this.setSpotTime(stop, stop.spotTime.timeAtStop)
      }

      const datetime = DateTime.fromISO(
        `${stop[`${stopType}Date`]}T${stop[`${stopType}Time`]}`,
        {
          zone: stop.address.timeZone,
        }
      )
      stop[`${stopType}Datetime`] = datetime.toISO()

      this.checkEvents()

      this.$set(this.tripData.stops, stopIndex, stop)
      this.setCalendarDays()
    },
    setDueDateKey(stopIndex) {
      this.checkEvents()
      if (stopIndex === 0) {
        this.$emit('set-due-date')
      }
    },
    async buildTripEstimationsPayload() {
      let tripData = deepClone(this.tripData)

      tripData.stops.forEach((stop) => {
        if (!stop.address.name && stop.address.addressName) {
          stop.address.name = stop.address.addressName
        }
      })

      let tripEstimationsPayload = { trips: [{ ...tripData }] }
      const tripEstimationsRequest = await this.getQuoteTripEstimations(
        tripEstimationsPayload
      ).catch((e) => e)

      return tripEstimationsRequest
    },

    async getTripEstimationForStop(stopIndex) {
      const stop = this.tripData.stops[stopIndex]
      const previousStop = this.tripData.stops[stopIndex - 1]

      let tripData = this.tripData

      tripData.stops.forEach((stop) => {
        if (!stop.address?.name && stop.address?.addressName) {
          stop.address.name = stop.address.addressName
        }
      })

      let tripEstimationsPayload = {
        trips: [{ ...tripData }],
      }

      const tripEstimationsRequest = await this.getQuoteTripEstimations(
        tripEstimationsPayload
      ).catch((e) => e)

      const tripEstimationsData = tripEstimationsRequest.data
      const tripEstimate = tripEstimationsData[this.tripIndex]
      const duration = tripEstimate.timesFromPreviousStop[stopIndex]
      const SECONDS_TO_MILLIS = 1000
      const durationDateTime = Duration.fromMillis(duration * SECONDS_TO_MILLIS)

      const previousStopPickupTime = DateTime.fromISO(
        `${previousStop?.pickupDate}T${
          this.tripData.stops[stopIndex - 1]?.pickupTime
        }`,
        { zone: previousStop.address.timeZone }
      )
      let arrivalTime = previousStopPickupTime
        .plus(durationDateTime)
        .setZone(stop.address.timeZone)

      arrivalTime = this.roundDateTimeUpToQuarterHour(arrivalTime)
      stop.dropoffDatetime = arrivalTime.toISO()
      stop.dropoffDate = arrivalTime.toISODate()
      stop.dropoffTime = arrivalTime.toFormat('HH:mm')

      this.$set(this.tripData.stops, stopIndex, stop)

      this.$forceUpdate()
    },
    roundDateTimeUpToQuarterHour(dateTime) {
      const minuteOffset =
        Math.ceil(dateTime.minute / 15) * 15 - dateTime.minute
      return dateTime.plus({
        minutes: minuteOffset,
        seconds: -1 * dateTime.second,
      })
    },
    isArrivalEstimatorDisabled(stopIndex) {
      const stop = this.tripData?.stops?.[stopIndex]
      const previousStop = this.tripData?.stops?.[stopIndex - 1]
      const isPreviousStopFilledOut =
        previousStop.address?.addressName &&
        previousStop?.pickupDate &&
        previousStop?.pickupTime
      return !isPreviousStopFilledOut || !stop.address?.addressName
    },
    editKnownAddress(address, stopIndex) {
      let title = 'Edit Stop Location'
      const component = () => import('./KnownAddressSidebar.vue')
      this.$store.dispatch(
        'app/openSidebarDialog',
        {
          data: { address, stopIndex, title, tripIndex: this.tripIndex },
          component,
        },
        true
      )
    },
    getRiskIcon(riskTypeId) {
      const supportedRiskTypeIds = Object.keys(this.riskIconMap)
      return supportedRiskTypeIds.length !== 0
        ? supportedRiskTypeIds.includes(String(riskTypeId))
        : false
    },
    hasFlightRisk(stop) {
      return stop?.risks?.some(
        (risk) => risk.riskTypeId === RiskTypeId.FlightRisk
      )
    },
    getStopFlightRisk(stop) {
      let flightInformation = stop?.flightInformation || null
      let risks = stop?.risks || null
      const isLastStop = stop.orderIndex === this.tripData.stops.length - 1
      const hasFlightRisk = this.hasFlightRisk(stop) || flightInformation
      const isAirportAddress = this.isAirportAddress(
        stop?.address?.addressTypes
      )
      const removeFlightRisk = isLastStop && isAirportAddress && hasFlightRisk
      const addFlightRisk = !isLastStop && isAirportAddress && !hasFlightRisk
      if (removeFlightRisk) {
        risks = stop?.risks?.filter(
          (risk) => risk.riskTypeId !== RiskTypeId.FlightRisk
        )
        flightInformation = null
      } else if (addFlightRisk) {
        risks = [
          ...(stop?.risks || []),
          {
            riskTypeId: RiskTypeId.FlightRisk,
            riskTypeLabel: 'Missing Flight Information',
            resolved: false,
            active: true,
          },
        ]
        flightInformation = stop.flightInformation || {
          flightNumber: null,
          airline: null,
        }
      }
      return { flightInformation, risks }
    },
    formatDisplayTime(date) {
      if (!date) {
        return null
      }
      return DateTime.fromISO(date).toFormat('MMM dd yyyy')
    },
    showDriverHotelSidebar() {
      this.openSidebarDialog({
        data: {
          tripId: this.tripData.tripId,
          hotelInformation: this.hotelInformation,
          title: 'Driver Hotel Information',
          firstStopDate: this.hotelInformation?.startDate || null,
          lastStopDate: this.hotelInformation?.endDate || null,
        },
        component: () => import('./DriverHotelSidebar.vue'),
      })
    },
    isWarningClickable(warning) {
      return warning.riskTypeId === RiskTypeId.DriverHotelRisk
    },
    handleWarningClick(warning) {
      if (warning.riskTypeId === RiskTypeId.DriverHotelRisk) {
        this.showDriverHotelSidebar()
      }
    },
    async handleDriverHotelCheck(isRequired) {
      const hotelRequirement = deepClone(
        this.$store.getters['tripHotelRequirement/hotelRequirement']
      )
      const tripId = hotelRequirement?.tripId || this.tripData.tripId
      this.$store.dispatch('tripHotelRequirement/setTripId', tripId)
      this.$store.dispatch('tripHotelRequirement/setIsRequired', isRequired)

      if (isRequired) {
        const checkIn =
          hotelRequirement?.checkIn ||
          DateTime.fromFormat(this.firstStopDate, 'yyyy-MM-dd').toISO()
        const checkOut =
          hotelRequirement?.checkOut ||
          DateTime.fromFormat(this.lastStopDate, 'yyyy-MM-dd').toISO()
        this.$store.dispatch('tripHotelRequirement/setCheckIn', checkIn)
        this.$store.dispatch('tripHotelRequirement/setCheckOut', checkOut)
        this.showDriverHotelSidebar()
      } else {
        await this.$store.dispatch('tripHotelRequirement/submit')
      }
    },
    hideStopNotePreview(stop) {
      return (
        this.isModeEdit &&
        (!stop.stopNotes ||
          !stop.stopNotes[0] ||
          (!stop.stopNotes[0].html && !stop.stopNotes[0].note)) &&
        !stop.notes &&
        (!stop.flightInformation ||
          (!stop.flightInformation.flightNumber &&
            !stop.flightInformation.airline))
      )
    },
    getStopLabel(stop, stopIndex) {
      const stopNumber = `Stop #${stopIndex + 1}`
      const title = stop?.address?.title
      if (!title) {
        return stopNumber
      }
      return `${stopNumber} - ${title}`
    },
    async handleDisableWarningClick(riskTypeId) {
      try {
        this.$store.dispatch('risks/addDisabledRiskType', riskTypeId)

        if (STOP_LEVEL_RISKS.includes(riskTypeId)) {
          this.resolveStopLevelRisks(riskTypeId)
        } else if (TRIP_LEVEL_RISKS.includes(riskTypeId)) {
          this.resolveTripLevelRisks(riskTypeId)
        }
      } catch (err) {
        logger.error(err)
      }
    },
    resolveStopLevelRisks(riskTypeId) {
      const resolveMissingStopWarning = this.isMissingStopWarningRiskType(
        riskTypeId
      )
      for (let stop of this.tripData?.stops) {
        for (let risk of stop.risks) {
          if (
            risk.riskTypeId === riskTypeId ||
            (resolveMissingStopWarning &&
              this.isMissingStopWarningRiskType(risk.riskTypeId))
          ) {
            risk.resolved = true
            risk.active = false
          }
        }
      }
    },
    isMissingStopWarningRiskType(riskTypeId) {
      return (
        riskTypeId === RiskTypeId.MissingStopRisk ||
        riskTypeId === RiskTypeId.IncompleteStopRisk
      )
    },
    resolveTripLevelRisks(riskTypeId) {
      for (let risk of this.reservationRisks) {
        if (risk.riskTypeId === riskTypeId) {
          risk.resolved = true
          risk.active = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ghost {
  background: $primary;
  opacity: 0.075;
}

.stop-type-container {
  display: flex;
  align-content: center;
  padding: 9px;
  margin-top: -4px;
  margin-right: 8%;
  margin-left: 8%;
  color: $white;
  text-transform: uppercase;
  background-color: $gray-medium-light;
  border-radius: 4px;

  span {
    position: relative;
    width: 100%;
    font-size: 14px;

    &.can-cancel {
      right: 6px;

      &.smaller-text {
        right: 3px;
      }
    }
  }

  .stop-type-icon {
    position: absolute;
    right: -4px;
    margin-top: 3px;
  }
}

.suggested-pricing-container {
  padding-top: 10px;
  .pricing-type {
    padding-left: 6px;
  }

  .price {
    padding-left: 6px;
  }
}

.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}

::v-deep .cr-modal {
  .v-text-field__details {
    display: none !important;
  }
}

@media only screen and (min-width: 1263px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 277px;
    }
  }
}

@media only screen and (min-width: 1500px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 349px;
    }
  }
}

@media only screen and (min-width: 1900px) {
  ::v-deep .cr-input-stop-address {
    label {
      max-width: 469px;
    }
  }
}
</style>
